import { useEffect, useState } from "react";
import { motion } from "framer-motion";
export default function useAlert(alert, type="error", duration=5) {

  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    if (alert) {
      showAlert();
    }
    let id;
    if (duration) {
        id = setTimeout(() => {
          clearAlert();
        }, 1000 * duration);
    }    
    return () => clearTimeout(id);
  }, [duration, alert])
  
  function showAlert() {
      setIsVisible(true);
  }

  function clearAlert() {
    setIsVisible(false);
  }

  function Alert() {
    return <motion.p 
        initial={{ y: -20, opacity: 0 }}
        animate={{ y: 0, opacity: 0.8 }}
        exit={{ y: -20, opacity: 0 }} 
        id="alert"
        onClick={clearAlert} 
        className={`${type}-alert`}>{ alert }
    </motion.p>
  }

  return {
    alertIsVisible: isVisible,
    Alert,
  }

}