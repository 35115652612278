export const fetchTestimonials = async () => {
    const response = await fetch("https://www.newpathwayscbt-api.com/testimonials");
    const resData = await response.json();
    if (!response.ok) {
        throw resData;
    }
    return resData.testimonials;
};

export const fetchFAQs = async () => {
    const response = await fetch("https://www.newpathwayscbt-api.com/faqs");
    const resData = await response.json();
    if (!response.ok) {
        throw resData;
    }
    return resData.faqs;
};