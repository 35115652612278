import { createContext, useContext, useState } from 'react';
import "./OptionBox.css";
const OptionBoxContext = createContext();

export default function OptionBox({ className, children, menuLocation='left', activeId=null }) {
    const [activeOptionId, setActiveOptionId] = useState(activeId);
    function toggleOption(id) {
        setActiveOptionId(prevId => {
            if (prevId === id) {
                return null;
            } else {
                return id;
            }
        })
    }

    const ctxValue = {
        activeOptionId,
        toggleOption,
    }

    return <OptionBoxContext.Provider value={ctxValue}>
        <div className={`${className} ${menuLocation}`}>
            {children}
        </div>
    </OptionBoxContext.Provider> 
}

OptionBox.List = List;
OptionBox.Option = Option;
OptionBox.Content = Content;

function useOptionBoxContext() {
    const ctx = useContext(OptionBoxContext);
    if (!ctx) {
        throw new Error('The useOptionBoxContext must be used within an OptionBox component.');
    }
    return ctx;
}

function List({children, className}) {
    return <ul className={className}>
        {children}
    </ul>
}

function Option({children, className, id}) {
    const {toggleOption, activeOptionId } = useOptionBoxContext();
    const isSelected = activeOptionId === id;
    return <li>
        <button className={`${className} ${isSelected ? 'selected' : ''}`} id={id} onClick={() => toggleOption(id)}>{children}</button>
    </li>
}    

function Content({children, className, id}) {
    const { activeOptionId } = useOptionBoxContext();
    const isSelected = activeOptionId === id;
    return <div className={`${className} ${ isSelected ? 'visible' : '' }`}>
        {children}
    </div>
}