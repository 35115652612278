import { useState, useEffect } from "react";

export default function useFetch(fetchFn, initialValue, fallback=null, errorMessage) {
  const [fetchedData, setFetchedData] = useState(initialValue);
  const [isFetching, setIsFetching] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    async function fetchData() {
      setIsFetching(true);
      try {
        const data = await fetchFn();
        setFetchedData(data);
      } catch (error) {
        setError(errorMessage || error.message); 
        if (fallback) {
            setFetchedData(fallback);
        }
      }
      setIsFetching(false);
    };
    fetchData();
  }, [fetchFn, fallback, errorMessage ]);

  return {
    fetchedData,
    isFetching,
    error,
  }
}