/* eslint-disable no-multi-str */

export const TESTIMONIALS = [
  {
    id: 1,
    quote:
      "I still have anxious days but I now feel like I have a really good toolbox to manage them well.",
    initials: "S.S.",
    location: "Washington",
  },
  {
    id: 2,
    quote:
      "I was anxious about trying any type of therapy, but I always feel a sense of relief and hope and stronger after a session with Hannah.",
    initials: "M.W.",
    location: "Missouri",
  },
  {
    id: 3,
    quote:
      "Hannah and CBT are helping me relearn my ABCs and reframe my thoughts! They are teaching me to sort myself out.",
    initials: "E.L.",
    location: "Missouri",
  },
  {
    id: 4,
    quote:
      "It was so helpful to learn how to identify common thought distortions so that I can challenge them.",
    initials: "N.M.",
    location: "Washington",
  },
  {
    id: 5,
    quote:
      "Hannah personalized and structured sessions to suit my neuro-diversity. This helped me work through the cause and effects of my struggles, giving me a greater understanding of my triggers and what different emotions look and feel like on me.",
    initials: "H.H.",
    location: "UK",
  },
  {
    id: 6,
    quote:
      "This course has helped me feel safer, less anxious, and more in control. I'm developing skills to assess and address faulty thinking patterns and replace them with accurate, empowering ones.",
    initials: "K.F.",
    location: "New York",
  },
  {
    id: 7,
    quote:
      "After implementing just a couple of suggestions, I feel like my brain fog is finally gone.",
    initials: "M.Z.",
    location: "Netherlands",
  },
];

export const FAQS = [
  {
    id: "faq1",
    question: "Is a CBT practitioner a therapist?",
    answer: "No. CBT practitioners are not licensed therapists. They cannot diagnose conditions nor prescribe medication. However, they follow the same therapeutic pattern and can guide you to similar results as therapy. If you are nervous about trying therapy, can't afford it, or simply haven't found the right therapist yet, a CBT practitioner might be a more affordable stepping stone toward better health.",   
  },
  {
    id: "faq2",
    question: "How long should I continue seeing a CBT practitioner?",
    answer: "The length of the program is up to you as the client. The full course can take up to 30 sessions. Every person is different. Some need a little less and some need a little more. For your own sense of achievement, I want to help you be in the drivers’ seat for this journey. You can decide if you want the whole course, or only certain sections. If one topic feels a little too much or too intimidating, I will explain its goal and importance, but it will always be up to you if it’s where you want to go right now. Whatever is true in your case, we believe that if you can invest the time to apply yourself fully, you will likely only need to complete a course like this once. Afterwards you'll have the tools to be your own coach. Of course, you are always welcome to come back for a check-in anytime you feel it is needed.",
  },
  {
    id: "faq3",
    question: "How much do sessions cost?",
    answer:
      "A single session is $80. However, a sliding scale of pricing is available so as many as possible have access to better mental health. Please contact me for a free consultation to discuss what is affordable for you.",
  },
  {
    id: "faq4",
    question: "How are sessions held?",
    answer: "Sessions are held via video-conference. They should be scheduled 3 days in advance via Calendly. Once scheduled, you will receive an appointment confirmation email with a Google Meet link. Each session lasts about 1 hour and follows a similar format. We will cover 2-3 agenda points relevant to your goals and decide which CBT practice/exercise would be beneficial for you to apply.",
  },
  {
    id: "faq5",
    question: "Can I pay through insurance?",
    answer: "At this time, we are not set up to accept insurance payments.",
  },
];
