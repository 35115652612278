import { Link } from 'react-router-dom';
import Button from '../components/ui/Button';

export default function Error() {
    return (
        <main class="page404__container">
            <h1>Page Not Found</h1>
            <p>The page you are looking for could not be found, but that's alright.</p>
            <p>There's still plenty more to explore back on the main website.</p>
            <p>Please check it out!</p>
            <Link to="/">
                <Button btnStyle="outline">Go to Homepage</Button>
            </Link>
        </main>
    )
}