import { Link, useLocation } from "react-router-dom";

import Accordion from "../../components/Accordion"
import useFetch from "../../hooks/useFetch.js";
import useAlert from "../../hooks/useAlert.js";
import { fetchFAQs } from "../../util/http.js";
import chevron from '../../assets/icons/chevron-right.svg';
import { FAQS } from "../../data/data.js";
import { AnimatePresence } from "framer-motion";

export default function FAQs() {
    const { state } = useLocation();
    const { fetchedData:faqs, isFetching, error } = useFetch(fetchFAQs, [], FAQS, "Could not load all Frequently Asked Questions.");
    const { alertIsVisible, Alert } = useAlert(error);
    return (
        <>
            <AnimatePresence>
                { alertIsVisible && <Alert />}
            </AnimatePresence>
            <div className="about">
                <div className='flex-container back'>
                    <Link to={ (state && state.previousPath) || '/about' } className='btn outline back'>&lt;&lt; Back</Link>
                    <h1>Frequently Asked Questions</h1>
                </div>            
                { isFetching && <p className="fallback-text">Loading...</p> } 
                { faqs.length > 0 && <Accordion className='accordion' image={chevron} alt='chevron icon'>
                        {faqs.map(faq => {
                            return (
                                <Accordion.Item key={faq.id} id={faq.id} className='accordion-item'>
                                    <Accordion.Title className='accordion-item-title'>
                                        {faq.question}
                                    </Accordion.Title>
                                    <Accordion.Content className='accordion-item-content'>
                                        <p>{faq.answer}</p>
                                    </Accordion.Content>
                                </Accordion.Item>
                            );
                        })}
                    </Accordion>}
                <div className="flex-container control-panel">
                    <h2>No more questions? Let's schedule a free consultation.</h2>
                    <Link to="/contact" className="btn emphasis">Schedule Now &gt;</Link>
                </div>
                <div className="faqs__form-container">
                    {/* This will eventually house a form where users can send in additional questions. */}
                </div>
            </div>
        </>
    )
}