import { createContext, useContext, useState } from 'react';
import { AnimatePresence, motion } from "framer-motion";

import "./Accordion.css";
// Accordion code 
const AccordionContext = createContext();

function useAccordionContext() {
    const ctx = useContext(AccordionContext);
    if (!ctx) {
        throw new Error('The useAccordionContext hook must be used in an Accordion component.');
    }    
    return ctx;
}    

export default function Accordion({children, className, image, alt='', activeId=null}) {
    const [openId, setOpenId] = useState(activeId);
    
    function toggleItem(id) {
        setOpenId(prevId => {
            if (id === prevId) {
                return null;
            } else {
                return id;
            }    
        })    
    }    

    const ctxValue = {
        openId,
        toggleItem,
        image,
        alt,
    };    
    
    return (
        <AccordionContext.Provider value={ctxValue}>    
            <ul className={className}>
                {children}
            </ul>    
        </AccordionContext.Provider>        
    )
}    

Accordion.Item = AccordionItem;
Accordion.Title = AccordionTitle;
Accordion.Content = AccordionContent;

// Accordion Item code
const AccordionItemContext = createContext();

function useAccordionItemContext() {
    const ctx = useContext(AccordionItemContext);
    if (!ctx) {
        throw new Error('The useAccordionItemContext hook must be used inside an Accordion.Item component.');
    }
    return ctx;
}

function AccordionItem({children, className, id}) {
    return <AccordionItemContext.Provider value={id}>
        <li className={className}>
            {children}
        </li>
    </AccordionItemContext.Provider> 
}

// Accordion Title code
function AccordionTitle({ children, className }) {
  const { toggleItem, image, alt, openId } = useAccordionContext();
  const id = useAccordionItemContext();
  const isOpen = id === openId;  
  return (
    <div  
      className={`${className} ${isOpen ? 'open' : ''}`}
      onClick={() => toggleItem(id)}
    >
      {image && <motion.span
            animate={ isOpen ? { rotate: 90 } : ""}
        ><img src={image} alt={alt} /></motion.span>}  
      <h3>{children}</h3>
    </div>    
  );
}  

// Accordion Content code 
function AccordionContent({ children, className }) {
    const {openId} = useAccordionContext();
    const id = useAccordionItemContext();
    const isOpen = openId === id;

    return <>
    <AnimatePresence mode="wait">
        { isOpen && <motion.div 
            
            initial={{ height: 0, scale: 0.5, y:-30, opacity: 0 }}
            animate={{ height: 'auto', scale: 1, y: 0, opacity: 1, transition: {duration: .2} }}
            exit={{ height: 0, opacity: 0, scale: 0.5, y: -30 }}
            className={`${className}`}>{children}</motion.div>
        }    
    </AnimatePresence>
    </>        
}

