import './Button.css';
export default function Button({ btnStyle, children, value, onClick=()=>{}, className='', ...props }) {
    return (
        <button
          onClick={() => onClick(value)}
          className={`btn ${className} ${btnStyle} `}
          {...props}
        >
            { children }
        </button>
    )
}