import { Outlet } from "react-router";
import { useLocation } from "react-router";
import { useEffect, useState } from "react";
import { AnimatePresence } from "framer-motion";
import NavBar from "../../components/navigation/NavBar";
import Footer from "../../components/Footer";
import "./Root.css";
import Sidebar from "../../components/Sidebar";

export default function Root() {
    const [isOpen, toggleOpen] = useState(false);

    function handleToggle() {
        toggleOpen(prev => {
            if (prev === false) {
                return true;
            } else {
                return false;
            }
        })
    }

    function handleClose() {
        toggleOpen(false);
    }

    const { pathname } = useLocation();
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

  
  
  return <div className="root-layout">
        <header>
            <NavBar onMenuClick={handleToggle} />
        </header>
        <main>
            <Outlet />
            <AnimatePresence>
                { isOpen && <Sidebar onClose={handleClose} /> }
            </AnimatePresence>
        </main>
        <Footer />
    </div>
}