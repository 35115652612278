import { motion } from "framer-motion";
import scrollUpArrow from  "../../assets/icons/scroll-up.svg";
import "./ScrollUp.css";
export default function ScrollUp() {
    function onScroll() {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth"
        });
    }
    return (
        <motion.img
            initial={{ scale: 0 }}
            animate={{ scale: 1, transition: { duration: .75, type: "spring", bounce: 0.7 } }}
            exit={{ scale: 0 }}
            whileTap={{ scale: [1.2, .85, 1.05, 1], transition: { duration: .2 }}}
            className="scroll-up"
            src={scrollUpArrow}
            alt="scroll up btn" 
            onClick={onScroll}
        />
    )
}