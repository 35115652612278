import { Link } from "react-router-dom";
import { motion } from "framer-motion";

export default function Sidebar({ onClose }) {
    const variants = {
        open: { opacity: 1, x: 0, transition: { duration: .2 } },
        closed: { opacity: 0, x: "100%", transition: { type: "tween", duration: .1 } }
    };
    return (
        <>
            <motion.div 
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                className="backdrop" onClick={onClose} />
            <motion.div 
                variants={variants}
                initial={"closed"}
                animate={"open"}
                exit={"closed"}
                className="sidebar">
                <motion.ul 
                    variants={{ 
                        open: {
                            transition: {
                                staggerChildren: .05
                            }
                        } 
                    }}
                    className="mobile-nav__list">
                    <motion.li
                      variants={{
                        open: {
                            y:0,
                            opacity: 1,
                        },
                        closed: {
                            y: 50,
                            opacity: 0,
                        }
                      }}
                    ><Link onClick={onClose} to="/">Home</Link></motion.li>
                    <motion.li
                      variants={{
                        open: {
                            y:0,
                            opacity: 1,
                        },
                        closed: {
                            y: 50,
                            opacity: 0,
                        }
                      }}
                    ><Link onClick={onClose} to="/about">About</Link></motion.li>
                    <motion.li
                      variants={{
                        open: {
                            y:0,
                            opacity: 1,
                        },
                        closed: {
                            y: 50,
                            opacity: 0,
                        }
                      }}
                    ><Link onClick={onClose} to="/blogs">Blog</Link></motion.li>
                    <motion.li
                      variants={{
                        open: {
                            y:0,
                            opacity: 1,
                        },
                        closed: {
                            y: 50,
                            opacity: 0,
                        }
                      }}
                    ><Link onClick={onClose} to="/contact">Contact</Link></motion.li>
                    {/* <motion.li
                      variants={{
                        open: {
                            y:0,
                            opacity: 1,
                        },
                        closed: {
                            y: 50,
                            opacity: 0,
                        }
                      }}
                    ><Link onClick={onClose} to="/login?mode=login">Login</Link></motion.li> */}
                </motion.ul>
            </motion.div>
        </>
    )
}