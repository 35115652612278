import NavItem from "./NavItem";
import logo from "../../assets/svgs/logo.svg";
import hamburger from "../../assets/icons/hamburger.svg";
import { Link } from "react-router-dom";

export default function NavBar({onMenuClick}) {

  return (
    <>
      <nav className="navbar mobile-nav">
        <ul className="nav__list">
          <li>
            <Link to="/">
              <img src={logo} alt="New Pathways logo" className="main-navbar__logo" />
            </Link>
          </li>
          <h1>New Pathways</h1>
          <li>
            <img onClick={onMenuClick} src={hamburger} id="menu-icon" alt="Menu Icon" />
          </li>
        </ul>
      </nav>
      <nav className="navbar main-nav">
        <ul className="nav__list">
          <li>
            <Link to="/">
              <img src={logo} alt="New Pathways logo" className="main-navbar__logo" />
            </Link>
          </li>
          <NavItem
            path="/"
          >
            Home
          </NavItem>
          <NavItem
            path="/about"
          >
            About
          </NavItem>
          <NavItem
            path="/blogs"
          >
            Blog
          </NavItem>
          <NavItem
            path="/contact"
          >
            Contact
          </NavItem>
          {/* <NavItem
            path="/login?mode=login"
            className="login-btn"
          >
            Login
          </NavItem> */}
        </ul>
      </nav>
    </>
  );
}
