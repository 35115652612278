import { Link } from "react-router-dom";
import useFetch from "../hooks/useFetch";
import useAlert from "../hooks/useAlert";
import { fetchTestimonials } from "../util/http";
import OptionBox from "../components/OptionBox";
import Accordion from "../components/Accordion";
import Testimonial from "../components/Testimonial";
import Carousel from "../components/Carousel/Carousel";
import Card from "../components/ui/Card";
import Avatar from "../components/ui/Avatar";
import logo from "../assets/images/main-logo-lg.jpg";
import waves from "../assets/backgrounds/waves-top.svg";
import curve from "../assets/backgrounds/curve-top.svg";
import slope from "../assets/backgrounds/slope.svg";
import hourglass from "../assets/svgs/hourglass.svg";
import sofa from "../assets/images/sofa.png";
import chevron from "../assets/icons/chevron-right.svg";
import gears from "../assets/svgs/gears.svg";
import { TESTIMONIALS as fallbackTestimonials } from "../data/data";
import "./Home.css";
import { AnimatePresence } from "framer-motion";


export default function Home() {
  const testimonialLoadErrorMessage = "Could not load all client testimonials.";
  const { fetchedData: testimonials, isFetching, error: fetchError } = useFetch(fetchTestimonials, [], fallbackTestimonials, testimonialLoadErrorMessage);
  const { Alert, alertIsVisible } = useAlert(fetchError);
  
  // Initial Carousel Settings
  let startingSlide = 0; 
  if (testimonials.length > 0) {
    startingSlide = Math.floor(Math.random() * testimonials.length);
  }

  return (
    <>
      <AnimatePresence>
        { alertIsVisible && <Alert /> }
      </AnimatePresence>

      <img
        className="main-logo"
        src={logo}
        alt="New Pathways logo of a little girl sitting under a tree."
      />

        <div className="curve-top">
          <img src={curve} alt="decorative green curve" />
        </div>

        {/* CALL TO ACTION SECTION */}
        <section className="call-to-action home__info-section">
          <h2>
            Take <span className="secondary-color-hover-span t5"><br/>Control<br/></span>{" "}
            of Your Emotions.
          </h2>
          <div className="flex-container">
            <h3>
              Learn Cognitive-Behavioral Therapy techniques that can help.
            </h3>
            <div className="flex-container control-panel">
              <Link to="/contact" className="btn light">
                New Appointment &gt;&gt;
              </Link>
              <Link to="/contact" className="btn">
                Free Consultation &gt;&gt;
              </Link>
            </div>
          </div>
        </section>
        <div className="waves">
          <img src={waves} alt="decorative blue waves" />
        </div>

        {/* HEAD FOR HOPE */}
        <section className="hope home__info-section">
          <h2>Head For <span className="t-secondary">Hope</span></h2>

          <p>New Pathways provides <span className="t-secondary">one hour CBT sessions</span> that are designed to help you...</p>
              <div className="home__profile-container">
                <Card className="home__profile">
                    <Avatar src={hourglass} alt="Hourglass" />
                    <h3>Save Time</h3>
                    <p>
                      Sessions have an end date. 
                      We learn together now so that you can independently navigate obstacles later.
                    </p>
                </Card>
                <Card className="home__profile center-card">
                    <Avatar src={gears} className="big-gear" alt="Rotating gears" />
                    <h3>Build Tools</h3>
                    <p>
                      Sessions equip you with new tools and techniques which will help empower you to take on future challenges successfully.  
                    </p>
                </Card>
                <Card className="home__profile">
                    <Avatar src={sofa} alt="Hourglass" />
                    <h3>Feel Comfortable</h3>
                    <p>CBT Practitioners move at a comfortable pace so you can ease into the more challenging topics.</p>
                </Card>  
              </div>
            <div className="flex-container control-panel">
              <Link 
                to="/about/course" 
                className="btn"
                state={{previousPath: "/"}}  
              >
                Course Overview &gt;&gt;
              </Link>
            </div>
        </section>
        <div className="slope">
          <img src={slope} alt="background transition" />
        </div>

        {/* HEAD FOR HEALING SECTION */}
        <section className="home__info-section healing">
          <h2>Head For <span className="t-secondary">Healing</span></h2>

          {/* ACCORDION DISPLAY USED ON MOBILE DEVICES */}
          <Accordion className='accordion' image={chevron} alt='chevron icon'>            
            <Accordion.Item id='acc-anxiety' className='accordion-item'>
              <Accordion.Title className='accordion-item-title'>
                Anxiety
              </Accordion.Title>
              <Accordion.Content className="accordion-item-content">
                <div>
                  <p>Learn how to interrupt the patterns that lead to or keep us stuck in anxious loops.</p>
                  <h4>Leave <span>Anxiety</span> Behind</h4> 
                </div>
              </Accordion.Content>
            </Accordion.Item>
            <Accordion.Item id='acc-depression' className='accordion-item'>
              <Accordion.Title className='accordion-item-title'>
                Depression
              </Accordion.Title>
              <Accordion.Content className="accordion-item-content">
                <div>
                  <p>Learn how to change the way you feel via changing the way you behave and think.</p>
                  <h4>Leave <span>Depression</span> Behind</h4>  
                </div>
              </Accordion.Content>
            </Accordion.Item>
            <Accordion.Item id='acc-memories' className='accordion-item'>
              <Accordion.Title className='accordion-item-title'>
                Difficult Memories
              </Accordion.Title>
              <Accordion.Content className="accordion-item-content">
                <div>
                  <p>Process difficult emotions by learning to reframe, show self-compassion, and forge a new relationship with your triggers.</p> 
                  <h4>Leave <span>Trauma</span> Behind</h4> 
                </div>
              </Accordion.Content>
            </Accordion.Item>
            <Accordion.Item id='acc-phobias' className='accordion-item'>
              <Accordion.Title className='accordion-item-title'>
                Phobias
              </Accordion.Title>
              <Accordion.Content className="accordion-item-content">
                <div>
                  <p>Take on phobias through gentle but effective exposure based experiences.</p>
                  <h4>Leave <span>Fears</span> Behind</h4> 
                </div>
              </Accordion.Content>
            </Accordion.Item>
            <Accordion.Item id='acc-relationships' className='accordion-item'>
              <Accordion.Title className='accordion-item-title'>
                Improved Relationships
              </Accordion.Title>
              <Accordion.Content className="accordion-item-content">
                <div>
                  <p>See what you can do to better understand those around you as well as practices which can help you to be better understood by others.</p>
                  <h4>Leave <span>Drama</span> Behind</h4> 
                </div>
              </Accordion.Content>
            </Accordion.Item>
            <Accordion.Item id='acc-neurodivergence' className='accordion-item'>
              <Accordion.Title className='accordion-item-title'>
                Neuro-Divergence
              </Accordion.Title>
              <Accordion.Content className="accordion-item-content">
                <div>
                  <p>CBT can help with the headaches of always having to translate the world around you, so you can finally take off the mask.</p>
                  <h4>Leave <span>Frustration</span> Behind</h4> 
                </div>
              </Accordion.Content>
            </Accordion.Item>
          </Accordion>

          {/* OPTION BOX DISPLAY USED ON DESKTOP DEVICES */}
          <OptionBox menuLocation="right" className="option-box option-box__home">
            <OptionBox.List className="option-box__list">
              <OptionBox.Option className="option-box__option" id="anxiety">
                Anxiety
              </OptionBox.Option>
              <OptionBox.Option className="option-box__option" id="depression">
                Depression
              </OptionBox.Option>
              <OptionBox.Option className="option-box__option" id="memories">
                Difficult Memories
              </OptionBox.Option>
              <OptionBox.Option className="option-box__option" id="phobias">
                Phobias
              </OptionBox.Option>
              <OptionBox.Option className="option-box__option" id="relationships">
                Improved Relationships
              </OptionBox.Option>
              <OptionBox.Option className="option-box__option" id="neurodivergence">
                Neuro-Divergence
              </OptionBox.Option>
            </OptionBox.List>
            <OptionBox.Content className="option-box__content" id="anxiety">
              <div>
                <p>Learn how to interrupt the patterns that lead to or keep us stuck in anxious loops.</p>
                <h4>Leave <span>Anxiety</span> Behind</h4> 
              </div>
            </OptionBox.Content>
            <OptionBox.Content className="option-box__content" id="depression">
              <div>
                <p>Learn how to change the way you feel via changing the way you behave and think.</p>
                <h4>Leave <span>Depression</span> Behind</h4>  
              </div>
            </OptionBox.Content>
            <OptionBox.Content className="option-box__content" id="memories">
              <div>
                <p>Process difficult emotions by learning to reframe, show self-compassion, and forge a new relationship with your triggers.</p> 
                <h4>Leave <span>Trauma</span> Behind</h4> 
              </div>
            </OptionBox.Content>
            <OptionBox.Content className="option-box__content" id="phobias">
              <div>
                <p>Take on phobias through gentle but effective exposure based experiences.</p>
                <h4>Leave <span>Fears</span> Behind</h4> 
              </div>
            </OptionBox.Content>
            <OptionBox.Content className="option-box__content" id="relationships">
                <div>
                <p>See what you can do to better understand those around you as well as practices which can help you to be better understood by others.</p>
                <h4>Leave <span>Drama</span> Behind</h4> 
                </div>
            </OptionBox.Content>
            <OptionBox.Content className="option-box__content" id="neurodivergence">
                <div>
                  <p>CBT can help with the headaches of always having to translate the world around you, so you can finally take off the mask.</p>
                  <h4>Leave <span>Frustration</span> Behind</h4> 
                </div>
            </OptionBox.Content>
            <OptionBox.Content className="option-box__content blur" id={null}>
              <div>
                <p>
                  Cognitive Behavioral Therapy is the process of examining the thoughts behind our emotions.
                  The mind is powerful, but you can learn to work with it to overcome a variety of challenges.
                </p>
                <p>
                  Select something you are struggling with and bring solutions into focus.
                </p>
              </div>
            </OptionBox.Content>
          </OptionBox>
          <div className="flex-container control-panel">
            <Link 
              to="/about/faqs" 
              className="btn outline-light secondary"
              state={{previousPath: "/"}}  
            >
              Check Out FAQs &gt;&gt;
            </Link>
          </div>
        </section>
        

        {/* HEAD FOR HAPPINESS SECTION */}
        <section className="home__info-section happiness">
          <h2>Head For <span className="t-secondary">Happiness</span></h2>
          <div className="home__testimonials-container">
            <div className="home__testimonial">
              { testimonials.length > 0 &&
                <Carousel
                  slideInfo={testimonials}
                  startAt={startingSlide}
                  timer={100}
                >
                  {(testimonial) => <Testimonial 
                    quote={testimonial.quote}
                    initials={testimonial.initials}
                    location={testimonial.location} 
                  />}
                </Carousel>
              } 
              { isFetching && <p className="fallback-text">Loading...</p> }
            </div>
          </div>
          <div className="flex-container control-panel">
            <Link 
              to="/about/coach" 
              className="btn"
              state={{previousPath: "/"}}  
            >
              Meet Your Coach &gt;&gt;
            </Link>
          </div>
        </section>
    </>
  );
}
