import { Link, useLocation } from "react-router-dom"

import Card from "../../components/ui/Card";
import hannah from '../../assets/images/profile-pic.jpg';

export default function Coach() {
    const { state } = useLocation();
    return (
        <div className="about coach">
            <div className='flex-container back'>
                <Link to={ (state && state.previousPath) || '/about' } className='btn outline back'>&lt;&lt; Back</Link>
                <h1>About Your Coach</h1>
            </div>
            <p>
                My name is Hannah and when I was in my early 20's, I got to experience first-hand the power of CBT. 
                At this time in my life, I had a series of blows which threw me from being a happy, outgoing and adventurous person to someone who could barely talk. 
                I was always sleepy but couldn’t get proper sleep. 
                I hated being alone with my thoughts but the idea of being around others filled me with unimaginable guilt and fear. 
                It was such a terrible weight to always carry around.
            </p>
            <Card className="about__coach__profile__card">
                <img src={hannah} alt="Hannah" />
                <div>
                    <p className="coach__name">Hannah Conrad</p>
                    <p className="coach__title">CBT Practitioner</p>
                </div>
            </Card>       
            <p> 
                I remember feeling trapped because I just didn’t know how to make things better and I couldn’t “just push on” anymore. 
                Thankfully, someone suggested I get some help from a CBT specialist they knew.  
                Honestly, my agreeing to do it was more from fear of disappointing my friend than actually believing it would help me.
                It was so hard at first to open up.  
                I vividly remember these thoughts and feelings and I try to bring this experience with me to my sessions. 
            </p>
            <p>
                As I worked with my CBT therapist I was deeply relieved to see how these simple techniques allowed me to begin the journey of emotionally recovering from past trauma. 
                I felt like all my emotional baggage now had wheels at least! 
                My past was still a part of me, but the weight was so much easier to bear, and I began really feeling like I was moving forward and able to open up on my terms.  
            </p>
            <p>    
                Gradually I was able to embrace a new curiosity for and balanced view of life. 
                Don't get me wrong; it took real work. 
                However, the results made such an impact that over the next decade I found myself hungry to know more about the brain, well-being and the whys behind the hows of CBT. 
            </p>
            <p>    
                The brain-body connection and our amazing design continues to fascinate me, the more I learn the more awe-struck I become. 
                So, as you might imagine, the idea to make my passion into my job began brewing a long time ago. 
                There just never seemed the time or circumstances to do what was needed to make that happen. 
                So, I just kept learning for fun and sharing what I could when I could in the hopes of helping.  
            </p>
            <p>
                In early 2023, my husband and I had the chance to move to Bangalore, India. 
                Suddenly, I had the time and the circumstances I had always needed to finally finish one of those CBT coaching courses I kept starting! 
                The ideas surrounding what I’d been learning and how I’d like to teach them began moving from my head to paper. 
                It was really exciting to see the course take shape! After a few brave souls let me try the beta course out on them, I was ready to go! 
            </p><p>    
                I truly love getting to work with amazing people who are brave enough to ask for and carve out the time to get help. 
                It makes me so happy to see people reach their potential! I can’t wait to meet you! 
            </p>
            <div className="flex-container control-panel">
                <h2>Ready to speak in person? Let's schedule a free consultation.</h2>
                <Link to="/contact" className="btn emphasis">Schedule Now &gt;</Link>
            </div>
        </div>
    )
}