import './Card.css';

export default function Card({ children, className, backgroundImg }) {
    return <div style={{ backgroundImage: `url(${backgroundImg ? backgroundImg : ''})`}} className={className}>
        {children}
    </div>
}




/* import './Card.css';

export default function Card({children, collapsed, onClick, data, className=''}) {
    return (
        <div onClick={() => {onClick(data.key)}} className={`${className} ${collapsed && 'collapsed'}`}>
            {collapsed ? data.title : children }
        </div>
    )
} */