import { NavLink } from "react-router-dom";
import './Nav.css';

export default function NavItem({ path, children, className='' }) {

    return (
        <li className={`main-nav__list-item ${className}`}>
            <NavLink to={ path } >
                { children }
            </NavLink>
        </li>
    )
        
}
