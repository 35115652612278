import { useState, useEffect, useCallback } from "react";
import { motion } from "framer-motion";
import Button from "../ui/Button";
import "./Carousel.css";

export default function Carousel({
  children,
  slideInfo,
  startAt = 0,
  timer = 8,
  delay = 0,
}) {
  
  const [slides, setSlides] = useState({
    activeSlide: startAt,
    firstSlide: true,
    action: "next",
  });

  let timeout = timer;
  if (slides.firstSlide) {
    timeout += delay;
  }

  // STYLING VARIANTS FOR SLIDE ENTRANCE AND EXIT
  const variants = {
    right: { x: "100%", opacity: 0 }, 
    left: { x: "-100%", opacity: 0 },
    visible: { x: 0, opacity: 1 }
  }
  
  const handleSlideChange = useCallback(
    (direction) => {
      if (direction === "next") {
        setSlides((prev) => {
          let nextSlide = prev.activeSlide;
          if (nextSlide === slideInfo.length - 1) {
            nextSlide = 0;
          } else {
            nextSlide += 1;
          }
          return {
            activeSlide: nextSlide,
            firstSlide: false,
            action: "next",
          };
        });
      } else if (direction === "previous") {
        setSlides((prev) => {
          let previousSlide = prev.activeSlide;
          if (prev.activeSlide === 0) {
            previousSlide = slideInfo.length - 1;
          } else {
            previousSlide -= 1;
          }
          return {
            activeSlide: previousSlide,
            firstSlide: false,
            action: "previous",
          };
        });
      }
    },
    [slideInfo.length]
  );

  useEffect(() => {
    const intervalId = setInterval(() => {
      handleSlideChange("next");
    }, 1000 * timeout);

    return () => {
      clearInterval(intervalId);
    };
  }, [slides.activeSlide, slides.firstSlide, timeout, handleSlideChange]);

  return (
    <div className="carousel__container">
      <Button
        className="carousel__direction-button"
        btnStyle="outline"
        onClick={() => handleSlideChange("previous")}
      >
        &lt;
      </Button>
      <div className="slide-container">
        <motion.div 
          variants={variants}
          initial={slides.action === "next" ? "right" : "left"}
          animate={"visible"}
          exit={slides.action === "next" ? "left" : "right"}
          key={slides.activeSlide} 
          className="slide"
        >
          {children(slideInfo[slides.activeSlide])}
        </motion.div>
      </div>
      <Button
        className="carousel__direction-button"
        btnStyle="outline"
        onClick={() => handleSlideChange("next")}
      >
        &gt;
      </Button>
    </div>
  );
}
