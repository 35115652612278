import calendar from "../assets/icons/add-calendar.svg";
import email from "../assets/icons/email-light.svg";
import instagram from "../assets/icons/instagram.svg";
import "./Footer.css";
import { Link } from "react-router-dom";

export default function Footer() {
  const year = new Date().getFullYear();

  return (
    <footer>
        <p>New Pathways &copy; {year}</p>
        <div className="footer__quick-links-container">
          <Link to="/contact">
            <img src={email} alt="Email icon" />
          </Link>
          <a 
            target="_blank"
            rel="noreferrer"
            href="https://calendly.com/newpathwayscbt">
            <img src={calendar} alt="Calendar icon" />
          </a>
          <a 
            target="_blank"
            rel="noreferrer"
            href="http://www.instagram.com/newpathwayscbt">
            <img src={instagram} alt="Instagram icon" />
          </a>
        </div>
    </footer>
  );
}
