import { useState } from "react";
import { Link, useLocation } from "react-router-dom";

import OptionBox from "../../components/OptionBox";

import ScrollUp from "../../components/ui/ScrollUp";
import './About.css';
import { AnimatePresence } from "framer-motion";

export default function Course() {
  const { state } = useLocation();
  const [showScrollUp, setShowScrollUp] = useState(false);

  window.addEventListener("scroll", () => {
    if (window.scrollY > 200 && !showScrollUp) {
      setShowScrollUp(true);
    } else if (window.scrollY < 200 && showScrollUp) {
      setShowScrollUp(false);
    }
  });

  return (
    <div className="about course">
      <div className="flex-container back">
        <Link to={ (state && state.previousPath) || '/about' } className="btn outline back">
          &lt;&lt; Back
        </Link>
        <h1>Course Overview</h1>
      </div>
      <OptionBox className="option-box" menuLocation="top">
        <OptionBox.List className="option-box__list">
          <OptionBox.Option className="option-box__option" id="course-section-1">
            Section 1
          </OptionBox.Option>
          <OptionBox.Option className="option-box__option" id="course-section-2">
            Section 2
          </OptionBox.Option>
          <OptionBox.Option className="option-box__option" id="course-section-3">
            Section 3
          </OptionBox.Option>
          <OptionBox.Option className="option-box__option" id="course-section-4">
            Section 4
          </OptionBox.Option>
        </OptionBox.List>

        <OptionBox.Content className="option-box__content" id={null}>
          <div className="course-section__summary">
            <h3>Get Started With Our Course Overview</h3>
            <p>
              Many feel nervous about beginning CBT sessions and aren't sure of what to expect.
              The purpose of this page is to give you a peak into what you will experience the New Pathways curriculum. 
            </p>
          </div>
          <div className="course-section__details">
            <p className="t-bold">Select one of the sections above to start learning more.</p>
            <p>
              Please note that the information contained on this page is intended to provide a <span className="italic">general</span> overview.
              In practice, sessions will be tailored to suit the needs of each individual.
            </p>
          </div>
        </OptionBox.Content>

        <OptionBox.Content className="option-box__content" id="course-section-1">
          <div className='course-section__summary'>
            <h3>Pick a New Direction</h3>
            <p>
              <span className="t-bold">Purpose: </span>Define your values. Identify actions that will feed those values, giving you a greater sense of fulfillment and purpose.
            </p>
            <p><span className="t-bold">Duration: </span>Approximately 4 - 6 sessions.</p>
          </div>
          <div className="course-section__details">
            <p className="t-bold">Why is it important?</p>
            <p>
              We are happiest when we live in harmony with our values. 
              However, with a little digging, many figure out that they have been living by somebody else's values or "rules". 
              This can leave us feeling powerless in our own life. 
              So, let’s figure out what's important to you. 
              What kind of person are you and what kind do you want to be?
              Before we can plot a course, we first need to know where you currently are and where you would like to head.
              
            </p>
          </div>
        </OptionBox.Content>

        <OptionBox.Content className="option-box__content" id="course-section-2">
        <div className='course-section__summary'>
            <h3>Healthy Habits</h3>
            <p>
              <span className="t-bold">Purpose: </span>Learn new habits which support and promote sustainable change.
            </p>
            <p><span className="t-bold">Duration: </span>Approximately 8 sessions.</p>
          </div>
          <div className="course-section__details">
            <p className="t-bold">Why is it important?</p>
            <p>Building healthy habits that play into your values will help prime your brain for growth, change and healing.
              What's even better is many of these are free and accessible to all.
              Such tried and tested habits include expressing gratitude, showing kindness, regular time outdoors, enjoyable exercise, and getting better sleep.
              You will learn how to build new helpful habits and overcome obstacles to change.
            </p>
          </div>
        </OptionBox.Content>

        <OptionBox.Content className="option-box__content" id="course-section-3">
          <div className='course-section__summary'>

            <h3>Understanding Our Thoughts and Feelings</h3>
            <p>
              <span className="t-bold">Purpose: </span>Learn to spot and dispute unhelpful, negative and biased thought patterns. 
            </p>
            <p><span className="t-bold">Duration: </span>Approximately 6 - 8 sessions.</p>
          </div>
          <div className="course-section__details">
            <p className="t-bold">Why is it important?</p>
            <p>
              The connection between thoughts and emotions is incredibly powerful. 
              At times, these two elements work together so quickly that you don't realize that one is affecting the other.
              By exploring this connection, you will learn to identify the thoughts behind your negative emotions.
              Once these thoughts are found, you'll learn how to fact-check and adjust them.
            </p>
            <p>
              We will discuss some common "thought distortions" to which we are all susceptible.
              Because thought distortions affect how we perceive information (a.k.a. "confirmation bias") it can be difficult to spot and dispute them without outside help.

            </p>
          </div>
        </OptionBox.Content>

        <OptionBox.Content className="option-box__content" id="course-section-4">
          <div className='course-section__summary'>

            <h3>Managing Core Beliefs and Affecting Change</h3>
            <p>
              <span className="t-bold">Purpose: </span>Get to the root of your thought distortions and limiting beliefs. 
            </p>
            <p><span className="t-bold">Duration: </span>Approximately 8 sessions.</p>
          </div>
          <div className="course-section__details">
            <p className="t-bold">Why is it important?</p>
            <p>
              Common thought distortions will usually reveal a running theme or core belief.
              If we don't understand our core belief, weed-like "thought distortions" will keep coming back.
              Once this belief has been identified, you will follow the same methods for addressing "thought distortions" to adjust the core belief to be more in line with reality.
              This will allow you to move onto a healthier, more fulfilling life.
            </p>
            <p>
              You'll also learn about additional tools at your disposal for maintaining your healthier, happier lifestyle.
            </p>
          </div>
        </OptionBox.Content>
      </OptionBox>
      <AnimatePresence>
        { showScrollUp && <ScrollUp /> }
      </AnimatePresence>
      <div className="flex-container control-panel">
          <h2>Ready to try the course for yourself? Let's schedule a free consultation.</h2>
          <Link to="/contact" className="btn emphasis">Schedule Now &gt;</Link>
      </div>
    </div>
  );
}
