import './Testimonial.css';
import woman1 from '../assets/images/woman01.svg';
import woman2 from '../assets/images/woman02.svg';
import woman3 from '../assets/images/woman03.svg';
import woman4 from '../assets/images/woman04.svg';
import woman5 from '../assets/images/woman05.svg';
import woman6 from '../assets/images/woman06.svg';
import woman7 from '../assets/images/woman07.svg';

const images=[woman1, woman2, woman3, woman4, woman5, woman6, woman7];

export default function Testimonial({ quote, initials, location }) {
    const imgIndex = Math.floor(Math.random() * images.length);

    return (
        <div className="testimonial__content-container">
            <img className='qtr-w' src={images[imgIndex]} alt='animated woman' />
            <p>"{ quote }"</p>
            <p className="subtitle"><span>- { initials }</span>, <span>{ location }</span></p>
        </div>
    )
}