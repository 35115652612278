import { lazy, Suspense } from 'react';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';

import Root from './pages/layouts/Root.jsx';
import Home from './pages/Home.jsx';
import Course from './pages/about/Course.jsx';
import Coach from './pages/about/Coach.jsx';
import Company from './pages/about/Company.jsx';
import FAQs from './pages/about/FAQs.jsx';
import Error from './pages/Error.jsx';
import './index.css';

// Lasy Loading Process
const Blogs = lazy(() => import("./pages/Blogs"));
const About = lazy(() => import("./pages/about/About"));
const Contact = lazy(() => import("./pages/Contact"));

const router = createBrowserRouter([
  {
    path: '/',
    element: <Root />,
    errorElement: <Error />,
    children: [
      {
        index: true,
        element: <Home />,
      },
      {
        path: '/about',
        children: [
          {
            index: true,
            element: <Suspense><About /></Suspense>
          },
          {
            path: 'course',
            element: <Course />
          },
          {
            path: 'coach',
            element: <Coach />
          },
          {
            path: 'company',
            element: <Company />
          },
          {
            path: 'faqs',
            element: <FAQs />
          },
        ]
      },
      {
        path: '/blogs',
        element: <Suspense><Blogs /></Suspense>,
      },
      {
        path: '/contact',
        element: <Suspense><Contact /></Suspense>,
      },
      {
        path: '/login',
        element: <Home />,
      },
      
    ]
  },
  
]);

const App = () => {
  return (
      <RouterProvider router={router} />
  )
}

export default App;